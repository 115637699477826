import store from '../../store/index'

const promoter = {
    nome: "",
    doc: "",
    rg: "",
    email: "",
    telefone: "",
    porcentagem: "",
    instagram: "",
    facebook: "",
    logradouro: "",
    numero: "",
    complemento: "",
    cep: "",
    cidade: "",
    bairro: "",
    uf: "",
    link_promoter: "",
    perm_cancel: true,
    create_link_promoter: true,
    perm_visualiza_qrcode: true,
    evento_id: 0,
    usuario_id: 0,
};

export default {
    open(callback=() => {}, data=promoter){
        store.commit('modalPromoter',{
            display: true,
            promoter: data,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalPromoter', {
            display: false,
            promoter: {},
            callbackOK: () => {}
        });
    },
}