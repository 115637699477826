<template>
  <div v-if="store.getters.modalConvidarColaborador.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4  sm:max-w-xl"
      role="dialog" id="modal">

      <div class="flex justify-end mr-2">
        <fa icon="xmark" size="lg" class="cursor-pointer lg:mx-2 iconBackMobile absolute text-gray-600"
          @click="close" />
      </div>

      <div class="p-7 flex flex-col gap-5 overflow-auto h-full" v-if="passoMock === false">
        <div>
          <h1 class="font-bold " style="color: #757575; font-size: 18px;">Configuração do acesso dos colaboradores</h1>
        </div>

        <div class="flex flex-col">
          <label class="ml-1 my-1">Email</label>
          <input type="email" v-model="colaboradorEmail"
            class="bg-white border border-gray-500 p-2 pr-10 rounded-lg w-full" />
        </div>

        <div class="grid grid-cols-2 gap-4">
          <!-- Coluna 1: Radio buttons -->
          <div class="flex flex-col space-y-2 text-gray-600">
            <h1 class="font-semibold">Tipo de acesso</h1>
            <!-- <div class="flex items-center">
              <input v-model="selectedRole" type="radio" id="produtor" name="role" class="" value="Produtor" />
              <label for="produtor" class="ml-2">Produtor</label>
            </div>-->
            <div class="flex items-center">
              <input v-model="selectedRole" type="radio" id="sub-produtor" name="role" class="" value="Sub Produtor" />
              <label for="sub-produtor" class="ml-2">Sub-Produtor</label>
            </div>
            <div class="flex items-center">
              <input v-model="selectedRole" type="radio" id="financeiro" name="role" class="" value="Financeiro" />
              <label for="financeiro" class="ml-2">Financeiro</label>
            </div>
            <div class="flex items-center">
              <input v-model="selectedRole" type="radio" id="gerenciador" name="role" class=""
                value="Gerenciador de Promoter" />
              <label for="gerenciador" class="ml-2">Gerenciador de Promoter</label>
            </div>
          </div>

          <!-- Coluna 2: Checkboxes -->
          <div class="flex flex-col space-y-2 text-gray-600">
            <h1 class="font-semibold">Eventos</h1>
            <div v-for="(evento, index) in eventosV" :key="index" class="mx-2 my-4">
              <input type="checkbox" :id="evento.id" class="" :value="evento.id" v-model="selectedEventos" />
              <label :for="evento.id" class="ml-2">{{ evento.nome }} </label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center" v-if="passoMock === false">
        <button class="botaoConfirmar" @click="confirmar">Convidar colaboradores</button>
      </div>

      <div class="p-7 flex flex-col gap-5 items-center overflow-auto" v-if="passoMock === true">
        <div>
          <h1 class="font-bold text-center" style="color: #757575; font-size: 18px;">Convite enviado</h1>
        </div>

        <img src="../../assets/images/ImagemConviteColaborador.png" width="250" height="250" class="mt-5">

        <div class="flex justify-center mt-7">
          <button class="botaoConfirmar" @click="reset">Convidar mais colaboradores</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";
import { POST } from "@/services/api";

export default {
  setup() {
    const store = useStore();
    const eventosV = ref()
    const eventoInfo = inject("evento") as any;
    const eventoID = eventoInfo.getEventoID()
    const colaboradorEmail = ref('')
    const passoMock = ref(false)
    const selectedRole = ref()
    const selectedEventos = ref([])
    const alert = inject("alert") as any;
    const loader = inject("loading") as any;

    const close = () => {
      reset()
      store.commit("modalConvidarColaborador", { display: false, eventos: {} });
    };


    watch(
      () => [store.getters.modalConvidarColaborador.display],
      () => {
        if (store.getters.modalConvidarColaborador.display) {
          eventosV.value = store.getters.modalConvidarColaborador.eventos
        } else {
          eventosV.value = {}
        }
      })

    const confirmar = async () => {
      const eventosID = [] as any

      selectedEventos.value.map((item) => {
        eventosID.push({ eventoId: item })
      })

      const body = {
        "email": colaboradorEmail.value,
        "tipoAcesso": selectedRole.value,
        "eventos": eventosID
      }

      if (body.email === '' || body.tipoAcesso === undefined || body.eventos.length === 0) {
        alert.open("Alerta", "Preencha todos os dados!", "Warning")
      } else {

        try {
          loader.open()

          const response = await POST("usuarios/invite_user", body)

          if(response.success)
            passoMock.value = true

        } catch (error) {
          console.log(error)
        } finally {
          loader.close()
        }
      }


      // passoMock.value = !passoMock.value
      //store.getters.modalConvidarColaborador.callbackOK();
    };

    const reset = () => {
      selectedRole.value = undefined
      selectedEventos.value = []
      colaboradorEmail.value = ''
      passoMock.value = false

    }


    return { store, close, eventosV, eventoID, confirmar, colaboradorEmail, passoMock, selectedRole, selectedEventos, reset };
  },
}
</script>

<style scoped>
input[type="checkbox"] {
  appearance: none;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #bbbbbb;
}

input[type="checkbox"]:checked {
  background: #7c1d55;
}

input[type="radio"] {
  appearance: none;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border-radius: 40px;
  cursor: pointer;
  border: 1px solid #bbbbbb;
}

input[type="radio"]:checked {
  background: #7c1d55;
}

.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #E95D88;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #E95D88;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #b94a6b;
  color: white;
}

.campoDados {
  color: #5E5E5E;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  padding: 3px 0px 3px 15px;
}

.resumoEmissao {
  border: 1px solid #E4E3E3;
  background-color: #FBFCFD;
  color: #8E8E8E;
}

.img {
  width: 150px;
  height: 150px;
}
</style>