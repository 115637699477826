<template>
  <div class="bg-gray-50" v-if="isMobile">
    <navbar/>
    <div class="flex flex-col h-screen">
      <div class="flex-1">
        <router-view />
      </div>
      <!--<div class="flex-none">
        <sidebar />
      </div>-->
    </div>
  </div>

 <div class="grid grid-cols-12" v-else >
  <div class="col-start-3 col-span-12 row-span-1">
    <div class="col-span-2 col-start-1 row-span-6 ">
      <sidebar />
    </div>

    <div class="col-start-3 col-span-12 row-span-1">
      <Header />
    </div>

    <div class="col-start-3 col-span-12 row-span-6 pt-8">
      <router-view />
    </div>

    <div class="hidden">
      <navbar />
    </div>
  </div>
 </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Sidebar from '../components/Elements/Sidebar.vue'
import Navbar from '../components/Elements/Navbar.vue'
import Header from '../components/Elements/Header.vue'

export default defineComponent({
  name: 'App',
  components: {
    'sidebar': Sidebar,
    'navbar': Navbar,
    Header,
  },
  data() {
    return {
      isMobile: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 900 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 900 ? true : false;
    });
  },
});

</script>
<style lang="scss" scoped>

.mobile {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100%;
}

.appContainer {
  overflow-x: hidden !important;
  max-width: max-content;
}

</style>

