const ListGerenciadorPromoter = [
    {
      icon: "home",
      label: "Dashboard",
      name: "Dashboard",
      path: "/dashboard",
      component: () => import("../views/Home.vue"),
    },  
    //  Promoter
    {
      show: false,
      icon: "users",
      label: "Promoters",
      name: "Promoters",
      path: "/promoters8d82651b52b5edceaa4218c2dc713255",
      component: () => import("../views/Promoter/index.vue"),
    },
    {
      show: false,
      icon: "users",
      label: "Emissão Voucher",
      name: "Emissão Voucher",
      path: "/promoters8d82651b52b5edceaa4218c2dc713255/emitirVoucher",
      component: () => import("../views/Promoter/emitirVoucher.vue"),
    },
    {
      show: false,
      icon: "users",
      label: "Liberar ingresso",
      name: "Liberar ingresso",
      path: "/promoters8d82651b52b5edceaa4218c2dc713255/liberarIngresso",
      component: () => import("../views/Promoter/liberarIngresso.vue"),
    },
    {
      show: false,
      name: "Extrato",
      path: "/promoters8d82651b52b5edceaa4218c2dc713255/:id/extrato",
      component: () => import("../views/Promoter/extrato.vue"),
    },
    {
      show: true,
      icon: "envelope",
      label: "Suporte",
      name: "Suporte",
      path: "/suporte",
      component: () => import("../views/Suporte/index.vue"),
    },
    {
      show: false,
      icon: 'user',
      name: "Minha Conta",
      path: "/perfil",
      component: () => import("../views/Perfil/index.vue")
    },
  ];
  
  export const ListMobileGerenciadorPromoter = [
    {
      icon: "home",
      label: "Dashboard",
      name: "Dashboard",
      path: "/dashboard",
      component: () => import("../views/Home.vue"),
    },
    //  Promoter
    {
      show: false,
      icon: "users",
      label: "Promoters",
      name: "Promoters",
      path: "/promoters8d82651b52b5edceaa4218c2dc713255",
      component: () => import("../views/Promoter/indexTemp.vue"),
    },
    {
      show: false,
      icon: "users",
      label: "Emissão Voucher",
      name: "Emissão Voucher",
      path: "/promoters/emitirVoucher",
      component: () => import("../views/Promoter/emitirVoucher.vue"),
    },
    {
      show: false,
      icon: "users",
      label: "Liberar ingresso",
      name: "Liberar ingresso",
      path: "/promoters8d82651b52b5edceaa4218c2dc713255/liberarIngresso",
      component: () => import("../views/Promoter/liberarIngresso.vue"),
    },
    {
      show: false,
      name: "Extrato",
      path: "/promoters8d82651b52b5edceaa4218c2dc713255/:id/extrato",
      component: () => import("../views/Promoter/extrato.vue"),
    },
    {
      show: true,
      icon: "envelope",
      label: "Suporte",
      name: "Suporte",
      path: "/suporte",
      component: () => import("../views/Suporte/index.vue"),
    },
    {
      show: true,
      icon: 'user',
      name: "Minha Conta",
      path: "/perfil",
      component: () => import("../views/Perfil/index.vue")
    },
  ];
  
  export default ListGerenciadorPromoter;
  
  
   
  