import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "w-full overflow-x-auto" }
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 0,
  class: "w-full h-full whitespace-no-wrap"
}
const _hoisted_4 = { class: "text-SC_SmallSubtitle font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "bg-white dark:divide-gray-700 dark:bg-gray-800" }
const _hoisted_7 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`px-2 py-2 text-sm `)
}
const _hoisted_8 = { class: "flex-column relative h-full justify-center items-center" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`px-4 py-4 text-sm `)
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { class: "font-bold" }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["title"]
const _hoisted_18 = {
  key: 1,
  class: "text-gray-700 border-none hover:text-primary hidden"
}
const _hoisted_19 = ["title"]
const _hoisted_20 = { key: 4 }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["title"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["onClick"]
const _hoisted_25 = { key: 5 }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = ["title"]
const _hoisted_28 = { key: 6 }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = ["title"]
const _hoisted_31 = { key: 7 }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = ["title"]
const _hoisted_34 = { key: 9 }
const _hoisted_35 = { key: 10 }
const _hoisted_36 = {
  key: 0,
  class: "flex relative h-full items-center px-4 py-4 text-sm"
}
const _hoisted_37 = { class: "flex h-full absolute top-0 justify-start items-center space-x-4 text-sm" }
const _hoisted_38 = ["onClick"]
const _hoisted_39 = ["title"]
const _hoisted_40 = {
  key: 1,
  class: "bg-gray-100 text-center font-medium py-3 flex justify-center items-center opacity-25 text-SC_Subtitle"
}
const _hoisted_41 = {
  class: /*@__PURE__*/_normalizeClass(`px-4 py-3 flex justify-between items-center text-xs font-semibold w-full h-full  text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800`)
}
const _hoisted_42 = {
  key: 0,
  class: "flex items-center text-SC_Text"
}
const _hoisted_43 = { class: "flex items-center" }
const _hoisted_44 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${$props.mobile
    ? 'w-full rounded-xl shadow-xs'
    : 'w-full overflow-hidden rounded-lg shadow-xs'
    }`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(`bg-gray-50 py-3 flex justify-between ${$props.mobile ? 'w-screen' : 'w-full'}`)
      }, [
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.perView) = $event)),
          class: "block px-3 mt-1 text-sm border bg-white form-select focus:border-gray-200 focus:outline-none focus:shadow-outline-green dark:focus:shadow-outline-gray"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.optionsViews, (option) => {
            return (_openBlock(), _createElementBlock("option", {
              key: option.text,
              value: option.value
            }, _toDisplayString(option.text), 9, _hoisted_2))
          }), 128))
        ], 512), [
          [_vModelSelect, $setup.perView]
        ]),
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.query) = $event)),
            class: "border rounded-sm p-2 text-sm",
            placeholder: "Filtrar"
          }, null, 512), [
            [_vModelText, $setup.query]
          ])
        ])
      ], 2),
      _cache[10] || (_cache[10] = _createElementVNode("hr", null, null, -1)),
      ($setup.copyData && $props.data && $setup.copyData.length > 0)
        ? (_openBlock(), _createElementBlock("table", _hoisted_3, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.headers, (header) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: header.value,
                    class: "px-4 py-4 text-SC_SmallSubtitle"
                  }, [
                    _createElementVNode("div", {
                      class: "flex items-center cursor-pointer text-SC_SmallSubtitle",
                      onClick: () =>
                  $setup.sortTable(
                    $setup.stateSort == 'dec' ? 'asc' : 'dec',
                    header.value,
                    header.isDate
                      ? 'date'
                      : typeof $setup.copyData[0][header.value]
                  )
                  
                    }, [
                      _createTextVNode(_toDisplayString(header.key) + " ", 1),
                      _cache[4] || (_cache[4] = _createElementVNode("i", { class: "gg-swap-vertical ml-2" }, null, -1))
                    ], 8, _hoisted_5)
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.copyData, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: item,
                  class: _normalizeClass(`text-gray-700 ${index % 2 != 0 ? 'bg-gray-100' : ''
              } dark:text-gray-400 text-SC_Text`)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.headers, (object) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: object }, [
                      (object.value != '' && object.photo)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _createElementVNode("img", {
                                class: "w-20 rounded-md",
                                src: item[object.value]
                              }, null, 8, _hoisted_9)
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (object.value != '')
                              ? (_openBlock(), _createElementBlock("td", _hoisted_10, [
                                  (object.date)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(object.deep
                          ? $setup.toDate($setup.deep(item, object.value))
                          : $setup.toDate(item[object.value])), 1))
                                    : (object.dateHour)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($setup.moment(item[object.value]).format("DD/MM/YYYY HH:mm:ss")), 1))
                                      : (object.sales)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                            _createElementVNode("div", null, _toDisplayString(item[object.value[1]]) + " ingressos", 1),
                                            _createElementVNode("div", _hoisted_14, _toDisplayString($setup.toMoney(item[object.value[0]])), 1)
                                          ]))
                                        : (object.download)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                                              (item.comprovante)
                                                ? (_openBlock(), _createElementBlock("button", {
                                                    key: 0,
                                                    class: "text-gray-700 border-none hover:text-primary",
                                                    onClick: ($event: any) => (object.click(item))
                                                  }, [
                                                    _createElementVNode("i", {
                                                      class: _normalizeClass(`gg-software-download`),
                                                      title: object.title
                                                    }, null, 8, _hoisted_17)
                                                  ], 8, _hoisted_16))
                                                : (_openBlock(), _createElementBlock("button", _hoisted_18, [
                                                    _createElementVNode("i", {
                                                      class: _normalizeClass(`gg-software-download`),
                                                      title: object.title
                                                    }, null, 8, _hoisted_19)
                                                  ]))
                                            ]))
                                          : (object.relatorio)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                                                _createElementVNode("button", {
                                                  class: "text-gray-700 border-none hover:text-primary mx-1",
                                                  onClick: ($event: any) => (object.action(item))
                                                }, [
                                                  _createElementVNode("i", {
                                                    class: _normalizeClass(`gg-file-document`),
                                                    title: object.title
                                                  }, null, 8, _hoisted_22)
                                                ], 8, _hoisted_21),
                                                (item.relatorio)
                                                  ? (_openBlock(), _createElementBlock("button", {
                                                      key: 0,
                                                      class: "text-gray-700 border-none hover:text-primary mx-1",
                                                      onClick: ($event: any) => (object.click(item))
                                                    }, _cache[5] || (_cache[5] = [
                                                      _createElementVNode("i", {
                                                        class: _normalizeClass(`gg-software-download`),
                                                        title: "Download Relatorio"
                                                      }, null, -1)
                                                    ]), 8, _hoisted_23))
                                                  : (_openBlock(), _createElementBlock("button", {
                                                      key: 1,
                                                      class: "text-gray-700 border-none hover:text-primary mx-1 hidden",
                                                      onClick: ($event: any) => (object.click(item))
                                                    }, _cache[6] || (_cache[6] = [
                                                      _createElementVNode("i", {
                                                        class: _normalizeClass(`gg-software-download`),
                                                        title: "Download Relatorio"
                                                      }, null, -1)
                                                    ]), 8, _hoisted_24))
                                              ]))
                                            : (object.trash)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_25, [
                                                  (item.tipo_produto != 103)
                                                    ? (_openBlock(), _createElementBlock("button", {
                                                        key: 0,
                                                        class: "text-gray-700 border-none hover:text-primary mx-1 flex justify-center",
                                                        onClick: ($event: any) => (object.action(item))
                                                      }, [
                                                        _createElementVNode("i", {
                                                          class: _normalizeClass(`gg-trash`),
                                                          title: object.title
                                                        }, null, 8, _hoisted_27)
                                                      ], 8, _hoisted_26))
                                                    : _createCommentVNode("", true)
                                                ]))
                                              : (object.qrcode)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_28, [
                                                    (item.tipo_produto != 103)
                                                      ? (_openBlock(), _createElementBlock("button", {
                                                          key: 0,
                                                          class: "text-gray-700 border-none hover:text-primary mx-1",
                                                          onClick: ($event: any) => (object.action(item))
                                                        }, [
                                                          _createElementVNode("i", {
                                                            class: _normalizeClass(`gg-qr`),
                                                            title: object.title
                                                          }, null, 8, _hoisted_30)
                                                        ], 8, _hoisted_29))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                : (object.carrinho)
                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                                                      _createElementVNode("button", {
                                                        class: "text-gray-700 border-none hover:text-primary mx-1",
                                                        onClick: ($event: any) => (object.action(item))
                                                      }, [
                                                        _createElementVNode("i", {
                                                          class: _normalizeClass(`gg-arrow-right-o`),
                                                          title: object.title
                                                        }, null, 8, _hoisted_33)
                                                      ], 8, _hoisted_32)
                                                    ]))
                                                  : (object.money)
                                                    ? (_openBlock(), _createElementBlock("span", {
                                                        key: 8,
                                                        class: _normalizeClass(`${$props.creditoDebito ? `text-${item['creditoDebito']}-500` : ''
                      }`)
                                                      }, _toDisplayString($setup.toMoney(item[object.value])), 3))
                                                    : (object.color)
                                                      ? (_openBlock(), _createElementBlock("span", _hoisted_34, [
                                                          _createElementVNode("div", {
                                                            style: _normalizeStyle(`height: 25px; width: 25px; background-color: ${item[object.value]
                        }; border-radius: 5px`)
                                                          }, null, 4)
                                                        ]))
                                                      : (object.document)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_35, [
                                                            (item[object.value].length > 11)
                                                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                                  _createTextVNode(_toDisplayString($setup.numberToCnpj(item[object.value])), 1)
                                                                ], 64))
                                                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                                  _createTextVNode(_toDisplayString($setup.numberToCpf(item[object.value])), 1)
                                                                ], 64))
                                                          ]))
                                                        : (_openBlock(), _createElementBlock("span", {
                                                            key: 11,
                                                            class: _normalizeClass(` ${object.badge
                      ? `px-3 py-1 rounded-full text-white capitalize bg-${object.badge[item[object.value]]
                      }-500`
                      : ''
                      }`)
                                                          }, _toDisplayString(object.deep
                          ? $setup.deep(item, object.value).length > 0
                            ? $setup.deep(item, object.value)
                            : ""
                          : object.badge
                            ? item[object.value] === 1
                              ? "Ativado"
                              : item[object.value]
                            : item[object.value]), 3))
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                    ], 64))
                  }), 128)),
                  ($setup.props.options && $setup.props.options.length > 0)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_36, [
                        _createElementVNode("div", _hoisted_37, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.options, (option) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: option.icon,
                              onClick: ($event: any) => (option.action(item))
                            }, [
                              _createElementVNode("i", {
                                class: _normalizeClass(`fa fa-${option.icon}`),
                                style: _normalizeStyle({ color: option.color }),
                                title: option.title
                              }, null, 14, _hoisted_39)
                            ], 8, _hoisted_38))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_40, _cache[7] || (_cache[7] = [
            _createTextVNode(" Nenhum item encontrado... "),
            _createElementVNode("i", { class: "ml-3 gg-ghost-character" }, null, -1)
          ]))),
      _createElementVNode("div", _hoisted_41, [
        ($props.data)
          ? (_openBlock(), _createElementBlock("span", _hoisted_42, " Mostrando " + _toDisplayString($setup.indexPagination * $setup.perView) + " - " + _toDisplayString($setup.copyData ? $setup.copyData.length : 0 + $setup.indexPagination * $setup.perView) + " de " + _toDisplayString($props.meta ? ` ${$props.meta.itemCount} ` : $props.data ? $props.data.length : 0), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_43, [
          ($setup.indexPagination > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-${$props.color}`),
                "aria-label": "Previous",
                onClick: _cache[2] || (_cache[2] = async () => {
              $setup.indexPagination--;
            })
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("svg", {
                  "aria-hidden": "true",
                  class: "w-4 h-4 fill-current",
                  viewBox: "0 0 20 20"
                }, [
                  _createElementVNode("path", {
                    d: "M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z",
                    "clip-rule": "evenodd",
                    "fill-rule": "evenodd"
                  })
                ], -1)
              ]), 2))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sizeOfList, (position) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: position }, [
              (position - 1 == $setup.indexPagination)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: _normalizeClass(`px-3 py-1 text-white transition-colors duration-150 bg-${$props.color} border border-r-0 border-${$props.color} rounded-md focus:outline-none focus:shadow-outline-${$props.color}`)
                  }, _toDisplayString(position), 3))
                : (position - 1 <= $setup.indexPagination + 3 && position > $setup.indexPagination - 1)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      onClick: async () => {
              $setup.indexPagination = position - 1;
            },
                      class: _normalizeClass(`px-3 py-1 rounded-md focus:outline-none focus:shadow-outline-${$props.color}`)
                    }, _toDisplayString(position), 11, _hoisted_44))
                  : _createCommentVNode("", true)
            ], 64))
          }), 128)),
          (($props.meta && $props.meta.hasNextPage) || $setup.indexPagination < $setup.sizeOfList - 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: _normalizeClass(`px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-${$props.color}`),
                "aria-label": "Next",
                onClick: _cache[3] || (_cache[3] = async () => {
              $setup.indexPagination++;
            })
              }, _cache[9] || (_cache[9] = [
                _createElementVNode("svg", {
                  class: "w-4 h-4 fill-current",
                  "aria-hidden": "true",
                  viewBox: "0 0 20 20"
                }, [
                  _createElementVNode("path", {
                    d: "M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z",
                    "clip-rule": "evenodd",
                    "fill-rule": "evenodd"
                  })
                ], -1)
              ]), 2))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 2))
}