<template>
    <template v-if="type === 'PromotersVinculados'">

        <div class="flex lg:flex-row-reverse flex-col items-center justify-between mb-2 mt-5">
            <div class="lg:w-32 w-full ">
                <button type="button" class="w-full buttonExcluir lg:mb-0 mb-4"
                    :class="{ 'botaoDesabilitado': !liberaBotao[type] }"
                    @click="emitDeletedRowsPromotersVinculados">Desvincular</button>
            </div>


            <div class="relative w-full lg:w-60">
                <input id="filter-text-box-vinculados" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54" />
            </div>
        </div>

        <div class="overflow-x-auto overflow-y-auto rounded-lg">
            <ag-grid-vue :rowData="rows" :columnDefs="columns" style="max-height: 48rem;" :defaultColDef="defaultColDef"
                class="ag-theme-quartz custom-ag-grid my-custom-grid" :pagination="true" :paginationPageSize="100"
                :rowSelection="'multiple'" :suppressRowClickSelection="true" :suppressRowDeselection="true"
                :localeText="localeText" :paginationPageSizeSelector="[10, 50, 100, 500]" @grid-ready="onGridReady"
                :rowHeight="45" :domLayout="'autoHeight'" @rowSelected="habilitaBotao(type)">
            </ag-grid-vue>
        </div>

    </template>

    <template v-if="type === 'PromotersDisponiveis'">

        <div class="flex lg:flex-row-reverse flex-col items-center justify-between mb-2 mt-5">
            <div class="lg:w-32 w-full ">
                <button type="button" class="w-full buttonVincular lg:mb-0 mb-4"
                    :class="{ 'botaoDesabilitado': !liberaBotao[type] }"
                    @click="emitSelectedVincularRows">Vincular</button>
            </div>

            <div class="relative w-full lg:w-60">
                <input id="filter-text-box-disponiveis" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54" />
            </div>
        </div>

        <div class="overflow-auto mb-10 rounded-lg">
            <ag-grid-vue :rowData="rows" :columnDefs="columns" style="max-height: 48rem;" :defaultColDef="defaultColDef"
                class="ag-theme-quartz custom-ag-grid my-custom-grid" :pagination="true" :paginationPageSize="100"
                :rowSelection="'multiple'" :suppressRowClickSelection="true" :suppressRowDeselection="true"
                :localeText="localeText" :paginationPageSizeSelector="[10, 50, 100, 500]" @grid-ready="onGridReady"
                :rowHeight="45" :domLayout="'autoHeight'" @rowSelected="habilitaBotao(type)">
            </ag-grid-vue>
        </div>

    </template>

    <template v-if="type === 'PromoterMovimentacao'">

        <div class="flex lg:flex-row flex-col-reverse lg:justify-between justify-center gap-3 mb-3 mt-3 noprint">
            <div class="relative w-full lg:w-60">
                <input id="filter-text-box-movimentacao" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54; font-size: 16px;" />
            </div>

            <div>
                <button class="button botaoCadastrarPromoter w-full" @click="onBtPrintMovimentacao('print')"
                    v-tooltip="'Imprimir'">
                    <fa icon="print" class="mx-1" />
                </button>
            </div>
        </div>

        <div id="myGrid" class="w-full .print-only tamanhoTabela">
            <ag-grid-vue :rowData="rows" :columnDefs="columns" style="height: 100%;" :defaultColDef="defaultColDef"
                class="ag-theme-quartz custom-ag-grid my-custom-grid w-full" :pagination="false"
                :paginationPageSize="100" :selectionColumnDef="selectionColumnDef" :selection="false"
                :localeText="localeText" :paginationPageSizeSelector="[10, 50, 100, 500]" @grid-ready="onGridReady"
                :rowHeight="45">
            </ag-grid-vue>
        </div>

    </template>

    <template v-if="type === 'PromoterMovimentacaoGeral'">

        <div class="flex lg:flex-row flex-col-reverse lg:justify-between justify-center gap-3 mb-3 mt-3 noprint">
            <div class="relative w-full lg:w-60">
                <input id="filter-text-box-movimentacao" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54; font-size: 16px;" />
            </div>

            <div class="">
                <button class="button botaoCadastrarPromoter w-full" @click="onBtPrintMovimentacaoGeral('print')"
                    v-tooltip="'Imprimir'">
                    <fa icon="print" class="mx-1" />
                </button>
            </div>
        </div>

        <div id="myGrid" class="w-full .print-only tamanhoTabela">
            <ag-grid-vue :rowData="rows" :columnDefs="columns" style="height: 100%;" :defaultColDef="defaultColDef"
                class="ag-theme-quartz custom-ag-grid my-custom-grid w-full" :pagination="false"
                :paginationPageSize="100" :selectionColumnDef="selectionColumnDef" :selection="false"
                :localeText="localeText" :paginationPageSizeSelector="[10, 50, 100, 500]" @grid-ready="onGridReady"
                :rowHeight="45">
            </ag-grid-vue>
        </div>

    </template>

    <template v-if="type === 'Emissao'">
        <div>
            <div class="relative w-full lg:w-60">
                <input id="filter-text-box-emissao" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54" />
            </div>
            <div class="mt-3">
                <ag-grid-vue :rowData="rows" :columnDefs="columns" style="max-height: 48rem; overflow-y: auto;"
                    :defaultColDef="defaultColDef" class="ag-theme-quartz custom-ag-grid my-custom-grid"
                    :pagination="true" :paginationPageSize="100" :localeText="localeText"
                    :paginationPageSizeSelector="[10, 50, 100, 500]" @grid-ready="onGridReady" :domLayout="'autoHeight'"
                    :rowHeight="45">
                </ag-grid-vue>
            </div>
        </div>
    </template>

    <template v-if="type === 'LiberarIngresso'">

        <div class="flex lg:flex-row flex-col items-center justify-between mb-2 mt-5">

            <div class="relative w-full lg:w-60">
                <input id="filter-text-box-vinculados" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54" />
            </div>
        </div>

        <div class="overflow-x-auto overflow-y-auto">
            <ag-grid-vue :rowData="rows" :columnDefs="columns" style="max-height: 48rem;" :defaultColDef="defaultColDef"
                class="ag-theme-quartz custom-ag-grid my-custom-grid" :pagination="true" :paginationPageSize="100"
                :rowSelection="'multiple'" :suppressRowClickSelection="true" :suppressRowDeselection="true"
                :localeText="localeText" :paginationPageSizeSelector="[10, 50, 100, 500]" @grid-ready="onGridReady"
                :domLayout="'autoHeight'">
            </ag-grid-vue>
        </div>

    </template>

    <template v-if="type === 'TabelaPDV'">
        <div class="flex lg:flex-row flex-col items-center justify-between mb-2 mt-5">
            <div class="relative w-full lg:w-60">
                <input :id="`filter-text-box-tabela-pdv-${pdvtype}`" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54" />
            </div>
        </div>

        <!-- Remova qualquer altura fixa e adicione domLayout: 'autoHeight' -->
        <div>
            <ag-grid-vue :rowData="rows" :columnDefs="columns" :defaultColDef="defaultColDef" :localeText="localeText"
                :domLayout="'autoHeight'" class="ag-theme-quartz custom-ag-grid my-custom-grid"
                @grid-ready="onGridReady">
            </ag-grid-vue>
        </div>
    </template>

    <template v-if="type === 'TabelaPie'">
        <div class="flex lg:flex-row flex-col items-center justify-between mb-2 mt-5">
            <div class="relative w-full lg:w-60">
                <input :id="`filter-text-box-tabela-pie`" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54" />
            </div>
        </div>

        <!-- Remova qualquer altura fixa e adicione domLayout: 'autoHeight' -->
        <div>
            <ag-grid-vue :rowData="rows" :columnDefs="columns" :defaultColDef="defaultColDef" :localeText="localeText"
                :domLayout="'autoHeight'" class="ag-theme-quartz custom-ag-grid my-custom-grid"
                @grid-ready="onGridReady">
            </ag-grid-vue>
        </div>
    </template>

    <template v-if="type === 'TabelaLine'">
        <div class="flex lg:flex-row flex-col items-center justify-between mb-2 mt-5">
            <div class="relative w-full lg:w-60">
                <input :id="`filter-text-box-tabela-line`" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54" />
            </div>
        </div>

        <!-- Remova qualquer altura fixa e adicione domLayout: 'autoHeight' -->
        <div>
            <ag-grid-vue :rowData="rows" :columnDefs="columns" :defaultColDef="defaultColDef" :localeText="localeText"
                :domLayout="'autoHeight'" class="ag-theme-quartz custom-ag-grid my-custom-grid"
                @grid-ready="onGridReady">
            </ag-grid-vue>
        </div>
    </template>

    <template v-if="type === 'Extrato'">
        <div class="flex lg:flex-row flex-col items-center justify-between mb-2">
            <div class="relative w-full lg:w-60">
                <input :id="`filter-text-box-tabela-extrato`" v-on:input="onFilterTextBoxChanged()" type="text"
                    placeholder="Filtrar" class="style-input w-full" />
                <fa icon="magnifying-glass" class="absolute left-2 top-1/2 transform -translate-y-1/2 ml-1"
                    style="color: #361D54" />
            </div>
        </div>


        <ag-grid-vue :rowData="rows" :columnDefs="columns" :defaultColDef="defaultColDef"
            class="ag-theme-quartz custom-ag-grid my-custom-grid" :pagination="true" :paginationPageSize="20"
            :localeText="localeText" :paginationPageSizeSelector="[10, 20, 50, 100, 500]" @grid-ready="onGridReady"
            :domLayout="'autoHeight'" :rowHeight="45">
        </ag-grid-vue>

    </template>


</template>

<script>
import { ref, inject } from "vue";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AG_GRID_LOCALE_BR } from "@/assets/locale/localeBR";
import { AgGridVue } from "ag-grid-vue3";
import { formatarMonetarioBRL, formatarData } from "@/services/formater";


export default {
    name: "DatatableAgGrid",
    components: {
        AgGridVue
    },
    props: {
        rows: Array,
        columns: Array,
        defaultColDef: Object,
        type: String,
        pdvtype: String
    },
    setup(props, { emit }) {
        const loader = inject("loading");
        const liberaBotao = ref([false])
        const gridApi = ref(null);
        const columnApi = ref(null);
        const selection = ref({
            mode: "multiRow",
            groupSelects: "descendants"
        });

        const selectionColumnDefLiberar = ref({
            sortable: true,
            pinned: "left",
            cellClass: "py-2"
        });

        const selectionColumnDef = ref({
            sortable: true,
            pinned: "left",
        });

        const onGridReady = (params) => {
            gridApi.value = params.api;
        };

        const onFilterTextBoxChanged = () => {
            if (props.type === "PromotersVinculados") {
                gridApi.value.setGridOption(
                    "quickFilterText",
                    document.getElementById("filter-text-box-vinculados").value,
                );
            }

            else if (props.type === "PromotersDisponiveis") {
                gridApi.value.setGridOption(
                    "quickFilterText",
                    document.getElementById("filter-text-box-disponiveis").value,
                );
            }

            else if (props.type === "PromoterMovimentacao") {
                gridApi.value.setGridOption(
                    "quickFilterText",
                    document.getElementById("filter-text-box-movimentacao").value,
                );
            }

            else if (props.type === "Emissao") {
                gridApi.value.setGridOption(
                    "quickFilterText",
                    document.getElementById("filter-text-box-emissao").value,
                );
            }

            else if (props.type === "TabelaPDV") {
                gridApi.value.setGridOption(
                    "quickFilterText",
                    document.getElementById(`filter-text-box-tabela-pdv-${props.pdvtype}`).value,
                );
            }

            else if (props.type === "TabelaPie") {
                gridApi.value.setGridOption(
                    "quickFilterText",
                    document.getElementById(`filter-text-box-tabela-pie`).value,
                );
            }

            else if (props.type === "TabelaLine") {
                gridApi.value.setGridOption(
                    "quickFilterText",
                    document.getElementById(`filter-text-box-tabela-line`).value,
                );
            }

            else if (props.type === "Extrato") {
                gridApi.value.setGridOption(
                    "quickFilterText",
                    document.getElementById(`filter-text-box-tabela-extrato`).value,
                );
            }
        };


        /* const getRowHeight = (params) => {
            console.log(params.data.ingressos.length)
            return params.data.ingressos.length > 1 ? 50*params.data.ingressos.length : 50
        }*/

        const localeText = ref(AG_GRID_LOCALE_BR);

        const emitDeletedRowsPromotersVinculados = () => {
            if (gridApi.value) {
                const selectedNodes = gridApi.value.getSelectedNodes();
                const selectedRows = selectedNodes.map(node => node.data);

                emit('get-selected-rows-promoters-vinculados', selectedRows);
            }
        };

        const emitSelectedVincularRows = () => {
            if (gridApi.value) {
                const selectedNodes = gridApi.value.getSelectedNodes();
                const selectedRows = selectedNodes.map(node => node.data);

                emit('get-selected-rows', selectedRows);
            }
        };

        const emitRowsLiberarIngresso = (tipoBotao) => {
            if (gridApi.value) {
                const selectedNodes = gridApi.value.getSelectedNodes();
                const selectedRows = selectedNodes.map(node => node.data);
                emit('get-selected-rows-liberar-ingresso', [selectedRows, tipoBotao]);
            }
        };

        const habilitaBotao = (types) => {
            if (gridApi.value) {
                const selectedNodes = gridApi.value.getSelectedNodes();
                const selectedRows = selectedNodes.map(node => node.data);

                if (selectedRows.length > 0)
                    liberaBotao.value[types] = true
                else
                    liberaBotao.value[types] = false
            }
        }

        const onBtPrintMovimentacaoGeral = (type) => {
            try {
                loader.open()
                let records = props.rows;
                if (!records?.length) {
                    records = props.rows;
                }
                const filename = `Movimentações do promoter -  ${String(props.rows[0].nomePromoter)}`;

                if (type === 'print') {
                    let rowhtml = '<p>' + filename + '</p>';
                    rowhtml +=
                        '<table style="width: 100%; " cellpadding="0" cellspacing="0"><thead><tr style="color: #515365; background: #eff5ff; -webkit-print-color-adjust: exact; print-color-adjust: exact;"> ';

                    props.columns
                        .filter((d) => !d.hide)
                        .map((d) => {
                            rowhtml += '<th>' + d.headerName + '</th>';
                        });
                    rowhtml += '</tr></thead>';
                    rowhtml += '<tbody>';

                    records.map((item) => {
                        rowhtml += '<tr>';
                        props.columns
                            .filter((d) => !d.hide)
                            .map((d) => {
                                let val = d.field.split('.').reduce((acc, part) => acc && acc[part], item);
                                let style = '';  // Define o estilo para cada célula

                                if (['valorVendido', 'valorDevido', 'valorPago', 'saldoDevedor'].includes(d.field)) {
                                    val = formatarMonetarioBRL(val);
                                }

                                if (d.field === 'nomeEvento') {
                                    style = 'font-weight: bold;';
                                }

                                if (d.field === 'data') {
                                    val = formatarData(val);
                                    style = 'font-weight: bold;';
                                }

                                if (d.field === 'valorVendido') {
                                    style = 'font-weight: bold;';
                                }

                                if (d.field === 'saldoDevedor') {
                                    if (item.saldoDevedor < 0) {
                                        style = 'color: red;';
                                    } else {
                                        style = 'color: green;';
                                    }
                                }

                                rowhtml += '<td style="' + style + '">' + val + '</td>';
                            });
                        rowhtml += '</tr>';
                    });
                    rowhtml +=
                        '<style>body {font-family:Arial; color:#495057;}p{text-align:center;font-size:18px;font-weight:bold;margin:15px;}table{ border-collapse: collapse; border-spacing: 0; }th,td{font-size:12px;text-align:left;padding: 4px;}th{padding:8px 4px;}tr:nth-child(2n-1){background:#f7f7f7; }</style>';
                    rowhtml += '</tbody></table>';

                    const winPrint = window.open('', '', 'left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0');
                    winPrint.document.write('<title>' + filename + '</title>' + rowhtml);
                    winPrint.document.close();
                    winPrint.focus();
                    winPrint.onafterprint = () => {
                        winPrint.close();
                    };
                    winPrint.print();
                }
            } catch (error) {
                console.log(error);
            } finally {
                loader.close()
            }
        };

        const onBtPrintMovimentacao = (type) => {
            try {
                loader.open()
                let records = props.rows;
                if (!records?.length) {
                    records = props.rows;
                }
                const filename = `Movimentações do promoter -  ${String(props.rows[0].nomePromoter)}`;

                if (type === 'print') {
                    let rowhtml = '<p>' + filename + '</p>';
                    rowhtml +=
                        '<table style="width: 100%; " cellpadding="0" cellspacing="0"><thead><tr style="color: #515365; background: #eff5ff; -webkit-print-color-adjust: exact; print-color-adjust: exact;"> ';

                    props.columns
                        .filter((d) => !d.hide)
                        .map((d) => {
                            rowhtml += '<th>' + d.headerName + '</th>';
                        });
                    rowhtml += '</tr></thead>';
                    rowhtml += '<tbody>';

                    records.map((item) => {
                        rowhtml += '<tr>';
                        props.columns
                            .filter((d) => !d.hide)
                            .map((d) => {
                                let val = d.field.split('.').reduce((acc, part) => acc && acc[part], item);
                                let style = '';  // Define o estilo para cada célula

                                if (['valor'].includes(d.field)) {
                                    val = formatarMonetarioBRL(val);
                                }

                                if (d.field === 'data') {
                                    val = formatarData(val);
                                }


                                rowhtml += '<td style="' + style + '">' + val + '</td>';
                            });
                        rowhtml += '</tr>';
                    });
                    rowhtml +=
                        '<style>body {font-family:Arial; color:#495057;}p{text-align:center;font-size:18px;font-weight:bold;margin:15px;}table{ border-collapse: collapse; border-spacing: 0; }th,td{font-size:12px;text-align:left;padding: 4px;}th{padding:8px 4px;}tr:nth-child(2n-1){background:#f7f7f7; }</style>';
                    rowhtml += '</tbody></table>';

                    const winPrint = window.open('', '', 'left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0');
                    winPrint.document.write('<title>' + filename + '</title>' + rowhtml);
                    winPrint.document.close();
                    winPrint.focus();
                    winPrint.onafterprint = () => {
                        winPrint.close();
                    };
                    winPrint.print();
                }
            } catch (error) {
                console.log(error);
            } finally {
                loader.close()
            }
        };


        return {
            selection,
            selectionColumnDef,
            onGridReady,
            localeText,
            emitDeletedRowsPromotersVinculados,
            emitSelectedVincularRows,
            habilitaBotao,
            liberaBotao,
            onFilterTextBoxChanged,
            onBtPrintMovimentacao,
            onBtPrintMovimentacaoGeral,
            emitRowsLiberarIngresso,
            selectionColumnDefLiberar
        };
    },
};
</script>

<style>
.ag-cell.ag-column-first {
    background-color: #D9D9D9;
}

.ag-paging-panel {
    background-color: #ffffff;
    color: #353535;
    font-weight: 500;
    padding-top: 25px;
    padding-bottom: 25px;
}

@media (max-width: 768px) {
    .ag-paging-panel {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        justify-items: center;
        gap: 5px;
        padding-bottom: 110px;
        padding-top: 15px;
    }
}

.ag-paging-button {
    background-color: #cccccc;
    padding: 10px;
    border-radius: 10px;
}

.ag-paging-button:hover {
    background-color: #d9d9d9;
}

.ag-paging-panel>.ag-paging-page-size .ag-wrapper {
    background-color: #cccccc;
}

.ag-theme-quartz .ag-filter-toolpanel-header,
.ag-theme-quartz .ag-filter-toolpanel-search,
.ag-theme-quartz .ag-status-bar,
.ag-theme-quartz .ag-header-row,
.ag-theme-quartz .ag-panel-title-bar-title,
.ag-theme-quartz .ag-multi-filter-group-title-bar,
.ag-theme-quartz-dark .ag-filter-toolpanel-header,
.ag-theme-quartz-dark .ag-filter-toolpanel-search,
.ag-theme-quartz-dark .ag-status-bar,
.ag-theme-quartz-dark .ag-header-row,
.ag-theme-quartz-dark .ag-panel-title-bar-title,
.ag-theme-quartz-dark .ag-multi-filter-group-title-bar,
.ag-theme-quartz-auto-dark .ag-filter-toolpanel-header,
.ag-theme-quartz-auto-dark .ag-filter-toolpanel-search,
.ag-theme-quartz-auto-dark .ag-status-bar,
.ag-theme-quartz-auto-dark .ag-header-row,
.ag-theme-quartz-auto-dark .ag-panel-title-bar-title,
.ag-theme-quartz-auto-dark .ag-multi-filter-group-title-bar {
    font-weight: 700;
}

.button {
    height: 40px;
    font-size: 14px;
}

.botaoCadastrarPromoter {
    background-color: #7D1D54;
    border: 1px solid #7D1D54;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: bold;
}
</style>
<style scoped>
.tamanhoTabela {
    height: 26rem;
}

@media (max-width: 768px) {
    .tamanhoTabela {
        height: 38rem;
    }
}

@page {
    size: A4 portrait;
    /* landscape, portrait */
    margin: 1cm;
    /* Define margens da página */
}

@media print {
    .noprint {
        display: none !important;
    }

    .print-only {
        display: block !important;
    }

    body {
        margin: 0;
        padding: 0;
        height: 100vh;
        overflow: hidden;
    }

    .print-only {
        page-break-after: avoid;
        height: auto;
        overflow: hidden;
    }
}

.custom-ag-grid {
    --ag-header-background-color: #ffffff;
    --ag-active-color: #ffffff00;
    --ag-odd-row-background-color: #eeeeee;
    --ag-header-foreground-color: #353535;
    --ag-header-cell-hover-background-color: #afafaf;
    --ag-row-hover-color: #d9d9d9;
    --ag-foreground-color: rgba(255, 255, 255, 0.87);
    --ag-data-color: #353535;
    --ag-checkbox-checked-color: #b1166e;
    --ag-checkbox-unchecked-color: #353535;
    --ag-checkbox-indeterminate-color: #b1166e;
    --ag-selected-row-background-color: #c2c2c2;
    --ag-column-hover-color: #691946 !important;
    --ag-input-focus-border-color: #7d1d54;
    --ag-borders: solid 1px;
    /*--ag-border-color: rgb(209, 209, 209);*/
    --ag-secondary-foreground-color: #353535;
    --ag-disabled-foreground-color: #353535;
    --ag-icon-font-color: #353535;

}

.botaoDesabilitado {
    opacity: 0.5;
    cursor: not-allowed;
}

.style-input {
    font-size: 16px;
    height: 38px;
    border-radius: 10px;
    border: 1.5px solid #777777;
    padding-left: 33px;
}

.style-input:focus {
    border: 2px solid #7c1d55;
    outline: none;
}
</style>
