import store from '../../store/index'

export default {
    open(emissao, callback=() => {}){
        store.commit('modalCancelarHistoricoEmissao',{
            display: true,
            emissao: emissao,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalCancelarHistoricoEmissao', {
            display: false,
            emissao: {},
            callbackOK: () => {}
        });
    },
}