import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog",
  id: "modal"
}
const _hoisted_3 = { class: "bg-white rounded-lg h-full w-full overflow-auto" }
const _hoisted_4 = { class: "flex flex-col justify-center items-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex flex-row resumoEmissao mt-5 p-2 gap-3" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "flex flex-col w-full" }
const _hoisted_9 = {
  class: "font-semibold",
  style: {"font-size":"20px"}
}
const _hoisted_10 = {
  class: "font-normal",
  style: {"font-size":"17px"}
}
const _hoisted_11 = {
  class: "font-normal",
  style: {"font-size":"17px"}
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "flex flex-col px-12 w-full" }
const _hoisted_14 = { class: "flex flex-row resumoEmissao mt-5 p-2 gap-3" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "flex flex-col w-full" }
const _hoisted_17 = {
  class: "font-semibold",
  style: {"font-size":"20px"}
}
const _hoisted_18 = {
  class: "font-normal",
  style: {"font-size":"17px"}
}
const _hoisted_19 = {
  class: "font-normal",
  style: {"font-size":"17px"}
}
const _hoisted_20 = { class: "flex justify-between mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.store.getters.modalCancelarHistoricoEmissao.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "title1 text-SC_Azul text-center p-5 mt-3" }, [
                _createElementVNode("span", { class: "font-normal" }, " Você realmente deseja cancelar essa emissão?")
              ], -1)),
              ($setup.emissaoV.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.emissaoV, (emissao, indexEmissao) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex flex-col px-12 w-full py-3",
                        key: indexEmissao
                      }, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("img", {
                            class: "img",
                            src: `https://santocartao-files.s3.amazonaws.com/event-pictures/${$setup.eventoID}.jpg`
                          }, null, 8, _hoisted_7),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("p", _hoisted_9, _toDisplayString(emissao.nome), 1),
                            _createElementVNode("p", _hoisted_10, _toDisplayString(emissao.descricao), 1),
                            _createElementVNode("p", _hoisted_11, [
                              _createElementVNode("span", null, _toDisplayString(emissao.quantidade), 1),
                              _cache[2] || (_cache[2] = _createTextVNode(" uni ")),
                              _createElementVNode("span", null, "R$ " + _toDisplayString(emissao.valor), 1)
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("img", {
                          class: "img",
                          src: `https://santocartao-files.s3.amazonaws.com/event-pictures/${$setup.eventoID}.jpg`
                        }, null, 8, _hoisted_15),
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("p", _hoisted_17, _toDisplayString($setup.emissaoV.nome), 1),
                          _createElementVNode("p", _hoisted_18, _toDisplayString($setup.emissaoV.descricao), 1),
                          _createElementVNode("p", _hoisted_19, [
                            _createElementVNode("span", null, _toDisplayString($setup.emissaoV.quantidade), 1),
                            _cache[3] || (_cache[3] = _createTextVNode(" uni ")),
                            _createElementVNode("span", null, "R$ " + _toDisplayString($setup.emissaoV.valor), 1)
                          ])
                        ])
                      ])
                    ])
                  ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("button", {
              class: "botaoFechar",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)))
            }, "Fechar"),
            _createElementVNode("button", {
              class: "botaoConfirmar",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.confirmar && $setup.confirmar(...args)))
            }, "Confirmar")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}