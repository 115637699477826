import store from '../../store/index'

export default {
    open(data='', pixInfo='', callback=() => {}){
        store.commit('modalPagamentoPix',{
            display: true,
            data:data,
            pixInfo: pixInfo,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalPagamentoPix', {
            display: false,
            data: {},
            pixInfo: {},
            callbackOK: () => {}
        });
    },
}