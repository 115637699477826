import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full h-5/6 px-4 py-3 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl",
  role: "dialog",
  id: "modal"
}
const _hoisted_3 = { class: "flex justify-center items-center relative" }
const _hoisted_4 = { class: "font-semibold text-xl text-SC_Azul" }
const _hoisted_5 = { class: "mt-4 mb-6 h-5/6 overflow-y-auto p-4 rounded-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!

  return ($setup.store.getters.modalCadastroPromoters.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", _hoisted_3, [
            _createElementVNode("h1", _hoisted_4, " Lista de promoters do evento " + _toDisplayString($setup.store.getters.modalCadastroPromoters.evento.nome), 1),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args))),
              class: "inline-flex items-center absolute right-0 justify-self-end w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700",
              "aria-label": "close"
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("svg", {
                class: "w-4 h-4",
                fill: "currentColor",
                viewBox: "0 0 20 20",
                role: "img",
                "aria-hidden": "true"
              }, [
                _createElementVNode("path", {
                  d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                  "clip-rule": "evenodd",
                  "fill-rule": "evenodd"
                })
              ], -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Datatable, {
              headers: $setup.headers,
              data: $setup.data,
              options: $setup.options,
              color: "primary"
            }, null, 8, ["headers", "data", "options"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}