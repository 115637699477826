import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full h-5/6 px-6 py-4 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog",
  id: "modal"
}
const _hoisted_3 = { class: "flex justify-end" }
const _hoisted_4 = { class: "mt-4 mb-6 h-5/6 overflow-y-auto" }
const _hoisted_5 = { class: "flex justify-center items-center my-2" }
const _hoisted_6 = { class: "text-xl" }
const _hoisted_7 = { class: "flex flex-col justify-center items-center" }
const _hoisted_8 = {
  key: 0,
  class: "flex justify-center my-5"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "flex flex-col content-center" }
const _hoisted_12 = { class: "flex justify-center" }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  class: "flex flex-col content-center w-56",
  style: {"margin":"10px"}
}
const _hoisted_15 = { class: "font-semibold text-center p-2 text-base" }
const _hoisted_16 = {
  class: "flex flex-col content-center",
  style: {"gap":"10px","margin-bottom":"10px"}
}
const _hoisted_17 = {
  class: "font-bold text-center text-red-500",
  style: {"padding":"0px","font-size":"20px","font-weight":"bold","color":"#ed1c24"}
}
const _hoisted_18 = {
  key: 0,
  class: "w-56",
  style: {}
}
const _hoisted_19 = {
  class: "text-xs text-center uppercase font-medium",
  style: {"font-size":"10px"}
}
const _hoisted_20 = {
  class: "text-xs text-center uppercase font-medium",
  style: {"font-size":"10px"}
}
const _hoisted_21 = {
  key: 1,
  class: "w-56",
  style: {}
}
const _hoisted_22 = {
  class: "text-xs text-center uppercase font-medium",
  style: {"font-size":"10px"}
}
const _hoisted_23 = { class: "flex justify-center" }
const _hoisted_24 = ["src"]
const _hoisted_25 = {
  class: "pos",
  id: "testes"
}
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = { class: "flex justify-center mt-5" }
const _hoisted_28 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.store.getters.modalIngresso.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", _hoisted_3, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args))),
              class: "inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700",
              "aria-label": "close"
            }, _cache[1] || (_cache[1] = [
              _createElementVNode("svg", {
                class: "w-4 h-4",
                fill: "currentColor",
                viewBox: "0 0 20 20",
                role: "img",
                "aria-hidden": "true"
              }, [
                _createElementVNode("path", {
                  d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                  "clip-rule": "evenodd",
                  "fill-rule": "evenodd"
                })
              ], -1)
            ]))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex justify-center bg-gray-100 h-16 items-center w-[238px]" }, [
              _createElementVNode("img", {
                src: "https://santocartao-files.s3.amazonaws.com/assets_voucher/logo.png",
                alt: "Santo Cartao",
                class: "h-10 w-[238px]"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, "Olá, " + _toDisplayString($setup.data.nome) + ", segue sua compra:", 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.venda_produto, (value, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "flex flex-col"
              }, [
                _createElementVNode("div", _hoisted_7, [
                  (value.qrcode)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "flex flex-col"
                      }, [
                        (index > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createElementVNode("img", {
                                src: `${$setup.assets}assets_voucher/iconPlus.png`,
                                alt: "asdass"
                              }, null, 8, _hoisted_9)
                            ]))
                          : _createCommentVNode("", true),
                        (index === 0)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 1,
                              src: `${$setup.assets}assets_voucher/top-ticket.png`,
                              alt: ""
                            }, null, 8, _hoisted_10))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("img", {
                              src: `${$setup.assets}product-pictures/${value.produto.cod_produto}.jpg`,
                              alt: "asdass",
                              style: {"width":"238px","height":"238px"}
                            }, null, 8, _hoisted_13)
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("p", _hoisted_15, [
                              _createElementVNode("b", null, _toDisplayString(value.produto.idEvento.nome) + " - " + _toDisplayString($setup.dataEvento(value.produto.idEvento)), 1),
                              _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" " + _toDisplayString(value.produto.detalhes), 1)
                            ])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _cache[6] || (_cache[6] = _createElementVNode("p", {
                            class: "text-center",
                            style: {"padding":"0px","line-height":"8px","font-size":"12px","font-weight":"normal"}
                          }, " Número do ingresso ", -1)),
                          _createElementVNode("span", _hoisted_17, _toDisplayString(`${$setup.data.transacao}/${value.qrcode.identificador}`), 1),
                          ($setup.data.promotersMovementsVenda)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                _createElementVNode("p", _hoisted_19, [
                                  _createTextVNode(" Promoter " + _toDisplayString($setup.data.promotersMovementsVenda.promoterMovements.promoterUsuario.nome) + " ", 1),
                                  _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                                  _createTextVNode(" " + _toDisplayString($setup.mask($setup.data.promotersMovementsVenda.promoterMovements.promoterUsuario.promoters.documento.replace(/\D/g,
                    ""))), 1)
                                ]),
                                _createElementVNode("p", _hoisted_20, [
                                  _createTextVNode(_toDisplayString($setup.data.promotersMovementsVenda.promoterMovements.promotersMovementsDetails.nome) + " ", 1),
                                  _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                                  _createTextVNode(" " + _toDisplayString($setup.mask($setup.data.promotersMovementsVenda.promoterMovements.promotersMovementsDetails.cpf.replace(/\D/g,
                    ""))), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          (!$setup.data.promotersMovementsVenda)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                _createElementVNode("p", _hoisted_22, [
                                  _createTextVNode(_toDisplayString($setup.data.nome) + " ", 1),
                                  _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                                  _createTextVNode(" " + _toDisplayString($setup.mask($setup.data.cpf.replace(/\D/g, ""))), 1)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("img", {
                            src: `${$setup.assets}assets_voucher/${value.qrcode.qrcode}.png`,
                            alt: "QRCode"
                          }, null, 8, _hoisted_24)
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", {
                            class: "custom-list-style",
                            innerHTML: value.produto.regras_produto
                          }, null, 8, _hoisted_26)
                        ]),
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("img", {
                            src: `${$setup.assets}assets_voucher/down-ticket.png`,
                            alt: "asdass"
                          }, null, 8, _hoisted_28)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}