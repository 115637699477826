import store from '../../store/index'

export default {
    open(emissao, callback=() => {}){
        store.commit('modalVendaPix',{
            display: true,
            emissao:emissao,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalVendaPix', {
            display: false,
            emissao: {},
            callbackOK: () => {}
        });
    },
}