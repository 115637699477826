<template>
  <div v-if="store.getters.modalVincularPromoter.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <div class="flex flex-col justify-center items-center">

          <span v-if="promoterV.length >= 1"></span>
          <img v-else src="@/assets/images/vincularPromoter.png" class="mt-5" width="150" heigth="150">


          <div class="title1 text-SC_Azul text-center p-5 mt-3">
            <span class="font-normal"> Você realmente deseja vincular o(s) promoter(s):

              <div v-if="promoterV.length >= 1">
                <p v-for="(promoter, index) in promoterV" :key="index" class="title1 font-bold text-SC_Azul my-3"> {{
                  promoter.promoter }}
                </p>
              </div>

              <div v-else>
              <p class="title1 font-bold text-SC_Azul my-3"> 
                {{ promoterV.value.promoter }}
              </p>
              </div>



              ao evento <p class="font-bold"> {{ eventoNome }} </p>
            </span>
          </div>
        </div>

      </div>
      <div class="flex flex-row justify-between mt-2">
        <button class="botaoFechar" @click="close">Fechar</button>
        <button class="botaoConfirmar" @click="confirmar">Confirmar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";

export default {
  setup() {
    const store = useStore();
    const promoterV = ref()
    const eventoInfo = inject("evento") as any;
    const eventoNome = ref('')

    const close = () => {
      store.commit("modalVincularPromoter", { display: false, promoter: {}, callbackOK: () => {} });
    };


    eventoNome.value = eventoInfo.getEvento().nome

    watch(
      () => [store.getters.modalVincularPromoter.display],
      () => {
        if (store.getters.modalVincularPromoter.display) {
          promoterV.value = store.getters.modalVincularPromoter.promoter
          if (!promoterV.value.length)
            promoterV.value = [promoterV.value]
        } else {
          promoterV.value = {}
        }
      })

    const confirmar = async () => {
      store.getters.modalVincularPromoter.callbackOK();
    };


    return { store, close, promoterV, eventoNome, confirmar };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #408135;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #408135;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #2a5523;
  color: white;
}
</style>