<template>
  <div v-if="store.getters.modalLiberarIngresso.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <div class="flex flex-col justify-center items-center">
          <div class="title1 text-SC_Azul text-center p-5 mt-3">
            <span class="font-normal">
              Você realmente deseja {{ tipoBotao }}
              <span v-if="tipoBotao !== 'Zerar'"> {{ liberacaoIngresso[1].quantidade }} </span>
              <span v-else> os </span> ingresso(s):

              <div class="flex flex-col gap-2 mt-3" v-for="(ingresso, indexIngressos) in ingressos"
                :key="indexIngressos">
                <p class="font-semibold">{{ ingresso.label }} - R$ {{ ingresso.valor }}</p>
              </div>

              <p class="my-3"> Para o(s) seguinte(s) promoter(s)? </p>

              <div class="flex flex-col gap-1" v-for="(promoters, indexPromoters) in liberacaoIngresso[0]"
                :key="indexPromoters">
                <p>{{ promoters.promoter }}</p>
              </div>

            </span>
          </div>
        </div>

      </div>
      <div class="flex flex-row justify-between mt-2">
        <button class="botaoFechar" @click="close">Fechar</button>
        <button class="botaoConfirmar" @click="confirmar">Confirmar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";

export default {
  setup() {
    const store = useStore();
    const liberacaoIngresso = ref()
    const eventoInfo = inject("evento") as any;
    const eventoNome = ref('')
    const ingressos = ref([]) as any
    const tipoBotao = ref('')

    const close = () => {
      store.commit("modalLiberarIngresso", { display: false, promoter: {}, callbackOK: () => { } });
    };

    const confirmar = async () => {
      store.getters.modalLiberarIngresso.callbackOK();
    };


    eventoNome.value = eventoInfo.getEvento().nome

    watch(
      () => [store.getters.modalLiberarIngresso.display],
      () => {
        if (store.getters.modalLiberarIngresso.display) {
          liberacaoIngresso.value = store.getters.modalLiberarIngresso.liberacaoIngresso
          ingressos.value = liberacaoIngresso.value[1].ingressos

          if (liberacaoIngresso.value[2].tipoBotao === "add")
            tipoBotao.value = "Liberar"
          else if (liberacaoIngresso.value[2].tipoBotao === "remove")
            tipoBotao.value = "Remover"
          else if (liberacaoIngresso.value[2].tipoBotao === "zerar")
            tipoBotao.value = "Zerar"

        } else {
          liberacaoIngresso.value = {}
        }
      })

    return { store, close, liberacaoIngresso, eventoNome, ingressos, confirmar, tipoBotao };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #408135;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #408135;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #2a5523;
  color: white;
}
</style>