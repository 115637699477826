import store from '../../store/index'

export default {
    open(evento, callback=() => {}){
        store.commit('modalConvidarColaborador',{
            display: true,
            evento:evento,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalConvidarColaborador', {
            display: false,
            evento: {},
            callbackOK: () => {}
        });
    },
}