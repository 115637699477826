import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return ($setup.store.getters.modalLogout.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _cache[0] || (_cache[0] = _createElementVNode("h6", { class: "text-center" }, "Atenção!", -1)),
            _createElementVNode("div", {
              innerHTML: $setup.store.getters.modalLogout.msg,
              class: "text-center"
            }, null, 8, _hoisted_3),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Button, {
                onClick: $setup.close,
                color: "gray-500",
                text: "Não"
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                onClick: $setup.confirmar,
                color: "primary",
                text: "Sim"
              }, null, 8, ["onClick"])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}