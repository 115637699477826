<template>
  <div v-if="store.getters.modalCadastroPainel.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-2/3 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4  sm:max-w-2xl"
      role="dialog" id="modal">

      <div class="p-7 flex flex-col gap-5 overflow-auto h-full">
        <div>
          <h1 class="font-bold " style="color: #757575; font-size: 18px;">Cadastro de usuário</h1>
        </div>

        <div class="flex flex-col gap-2">

          <div class="flex flex-col">
            <label class="ml-1 my-1 text-gray-500">Nome</label>
            <input type="text" class="bg-white border border-gray-500 p-2 pr-10 rounded-lg w-full"
              v-model="data.nome" />
          </div>

          <div class="flex flex-row gap-2">
            <div class="flex flex-col w-full">
              <label class="ml-1 my-1 text-gray-500">Cidade</label>
              <input type="text" class="bg-white border border-gray-500 p-2 pr-10 rounded-lg w-full"
                v-model="data.cidade" />
            </div>

            <div class="flex flex-col w-full">
              <label class="ml-1 my-1 text-gray-500">Bairro</label>
              <input type="text" class="bg-white border border-gray-500 p-2 pr-10 rounded-lg w-full"
                v-model="data.bairro" />
            </div>
          </div>

          <div class="flex flex-row gap-2">
            <div class="flex flex-col w-full">
              <label class="ml-1 my-1 text-gray-500">Estado</label>
              <input type="text" class="bg-white border border-gray-500 p-2 pr-10 rounded-lg w-full"
                v-model="data.uf" />
            </div>

            <div class="flex flex-col w-full">
              <label class="ml-1 my-1 text-gray-500">Documento</label>
              <input type="text" class="bg-white border border-gray-500 p-2 pr-10 rounded-lg w-full" v-maska
              :data-maska="maskaDocumento" @input="handleInputDocumento"
                v-model="data.documento" />
            </div>
          </div>

          <div class="flex flex-col">
            <label class="ml-1 my-1 text-gray-500">Telefone</label>
            <input type="text" class="bg-white border border-gray-500 p-2 pr-10 rounded-lg w-full" v-maska
              :data-maska="maskaTelefone" @input="handleInputTelefone" v-model="data.telefone" />
          </div>

          <div class="flex flex-col">
            <label class="ml-1 my-1 text-gray-500">Senha</label>
            <input type="password" class="bg-white border border-gray-500 p-2 pr-10 rounded-lg w-full"
              v-model="data.senha" />
          </div>

        </div>


      </div>
      <div class="flex justify-center p-2">
        <button class="botaoConfirmar" @click="confirmar">Cadastrar</button>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";
import { POST } from "@/services/api";

export default {
  setup() {
    const store = useStore();
    const eventosV = ref()
    const eventoInfo = inject("evento") as any;
    const eventoID = eventoInfo.getEventoID()
    const alert = inject("alert") as any;
    const loader = inject("loading") as any;
    const maskaTelefone = ref('');
    const maskaDocumento = ref('');

    const data = ref({
      nome: '',
      cidade: '',
      bairro: '',
      uf: '',
      documento: '',
      telefone: '',
      senha: ''
    })

    const reset = () => {
      data.value = {
        nome: '',
        cidade: '',
        bairro: '',
        uf: '',
        documento: '',
        telefone: '',
        senha: ''
      }
    }

    const close = () => {
      reset()
      store.commit("modalCadastroPainel", { display: false, eventos: {} });
    };


    const confirmar = async () => {
      store.getters.modalCadastroPainel.callbackOK(data.value);
      close()
    };

    const handleInputTelefone = () => {
      const value = data.value.telefone.replace(/\D/g, '');

      if (value.length <= 10) {
        maskaTelefone.value = '(##) ####-####';
      } else {
        maskaTelefone.value = '(##) #####-####';
      }

    };

    const handleInputDocumento = () => {
      const value = data.value.documento.replace(/\D/g, '');

      if (value.length === 11) {
        maskaDocumento.value = '###.###.###-##'; // CPF
      } else if (value.length > 11) {
        maskaDocumento.value = '##.###.###/####-##'; // CNPJ
      } else {
        maskaDocumento.value = '##############';
      }

    }


    return { store, close, eventosV, eventoID, confirmar, reset, data, maskaTelefone, handleInputTelefone, maskaDocumento, handleInputDocumento };
  },
}
</script>

<style scoped>
.botaoConfirmar {
  border: 1px solid #E95D88;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #E95D88;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #b94a6b;
  color: white;
}

.campoDados {
  color: #5E5E5E;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  padding: 3px 0px 3px 15px;
}

.resumoEmissao {
  border: 1px solid #E4E3E3;
  background-color: #FBFCFD;
  color: #8E8E8E;
}

.img {
  width: 150px;
  height: 150px;
}
</style>