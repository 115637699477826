import axios from 'axios';
import router from '../router'
const isProd = process.env.NODE_ENV === 'production';
//const isProd = true
const instance = axios.create({
    baseURL: (isProd) ? 'https://api.santocartao.com.br/api' : 'http://localhost:3000/api',
    headers: {
        'Authorization': localStorage.getItem("token_meu_evento") ? `Bearer ${localStorage.getItem("token_meu_evento")}` : "",
    }
})

instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token_meu_evento');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


const GET = (url: string | void) => instance.get(url || "").then(res => res.data).then(res => {
    return res
}).catch(e => {
    if (e.response.status == 403 || e.response.status == 401) {
        router.push({ path: '/login' })
        localStorage.clear();
    }
    throw e.response.data.msg
})

const POST = (url: string | void, params: object | Array<any | void> | void) => instance.post(url || "", params).then(res => res.data).then(res => {
    return res
}).catch(e => { 
    if (e.response.status == 403 || e.response.status == 401) {
        router.push({ path: '/login' })
        localStorage.clear();
    }
    throw e.response.data.msg
})

const PUT = (url: string | void, params: object | Array<any> | void) => instance.put(url || "", params).then(res => res.data).then(res => {
    return res
}).catch(e => {
    if (e.response.status == 403 || e.response.status == 401) {
        router.push({ path: '/login' })
        localStorage.clear();
    }
    throw e.response.data.msg
})

const DELETE = (url: string | void) => instance.delete(url || "").then(res => res.data).then(res => {
    console.log(res);
    return res
}).catch(e => {
    if (e.response.status == 403 || e.response.status == 401) {
        router.push({ path: '/login' })
        localStorage.clear();
    }
    console.log(e);
    throw e.response.data.msg
})


const setBearerToken = async (token: string) => {
    instance.defaults.headers.Authorization = `Bearer ${token}`
}


const LOGIN = (route: string, payload: object | void): Promise<string> => instance.post(route, payload).then(res => res.data).then(async res => {

    await setBearerToken(res.token);

    return res.token;
}).catch(e => {
    if (e.response.status == 403 || e.response.status == 401) {
        router.push({ path: '/login' })
        localStorage.clear();
    }
    throw e.response.data.message
})


//const isProd= false
const instanceApiVenda = axios.create({
    baseURL: (isProd) ? 'https://apivendas.santocartao.com.br/api' : 'http://127.0.0.1:7012/api',
    headers: {
        'Authorization': localStorage.getItem("token_meu_evento") ? `Bearer ${localStorage.getItem("token_meu_evento")}` : "",
        ['keyApiVendas']: '23tbOaox0uYsAO2pKziXQjqZc4TKsZwaLhsjy7JCkywDQZpDv6'
    }
})


const GET_VENDAS = (url: string | void) => instanceApiVenda.get(url || "").then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

const POST_VENDAS = (url: string | void, params: object | Array<any | void> | void) => instanceApiVenda.post(url || "", params).then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

const PUT_VENDAS = (url: string | void, params: object | Array<any> | void) => instanceApiVenda.put(url || "", params).then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

const DELETE_VENDAS = (url: string | void) => instanceApiVenda.delete(url || "").then(res => res.data).then(res => {
    return res
}).catch(e => {

    throw e.response.data.message
})

export { GET, GET_VENDAS, POST, POST_VENDAS, PUT, PUT_VENDAS, DELETE, DELETE_VENDAS, LOGIN, instance };