import { createStore } from "vuex"

type Filtro = {
    name: string;
    data: filtrochildren[];
}

type filtrochildren = {
    id: number;
    name: string;
}



const Filterstore = createStore({
    state: {
        filtroVenda: [],
        filtroPortaria: [],
        selectedVenda: [],
        filtroPromoterProduto: []
    },

    getters: {
        getfiltroVenda: (state) => state.filtroVenda,
        getfiltroPortaria: (state) => state.filtroPortaria,
        getselectedVenda: (state) => state.selectedVenda,
        getfiltroPromoterProduto: (state) => state.filtroPromoterProduto
    },
    mutations: {
        setFiltroVenda: (state, value) => state.filtroVenda = value,
        setFiltroPortaria: (state, value) => state.filtroPortaria = value,
        setSelectedVenda: (state, value) => state.selectedVenda = value,
        setFiltroPromoterProduto: (state, value) => state.filtroPromoterProduto = value
    }


})

export default Filterstore