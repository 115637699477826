import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog",
  id: "modal"
}
const _hoisted_3 = { class: "bg-white rounded-lg h-full w-full overflow-auto" }
const _hoisted_4 = { class: "flex flex-col justify-center items-center" }
const _hoisted_5 = {
  class: "flex flex-row justify-center items-center mb-3 bg-yellow-200 p-4",
  style: {"color":"#FF9800"}
}
const _hoisted_6 = { class: "title1 text-SC_Azul text-center p-5 mt-3" }
const _hoisted_7 = { class: "title1 font-bold text-SC_Azul my-3" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { class: "flex justify-between mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return ($setup.store.getters.modalExcluirPromoterPermanente.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_fa, {
                  icon: `triangle-exclamation`,
                  style: {"padding":"7px 10px 7px 10px","font-size":"50px"}
                }),
                _cache[2] || (_cache[2] = _createElementVNode("p", {
                  class: "mt-2",
                  style: {"font-size":"20px"}
                }, [
                  _createTextVNode("Você realmente deseja excluir esse promoter "),
                  _createElementVNode("b", null, "permanentemente"),
                  _createTextVNode("?")
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, [
                  _createElementVNode("b", null, _toDisplayString($setup.promoterV.promoter), 1)
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("p", null, "Eventos vinculados:", -1)),
                _createElementVNode("p", _hoisted_8, _toDisplayString($setup.eventoNome), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", {
              class: "botaoCancelar",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)))
            }, "Cancelar"),
            _createElementVNode("button", {
              class: "botaoConfirmar",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.confirmar && $setup.confirmar(...args)))
            }, "Confirmar")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}