import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog",
  id: "modal"
}
const _hoisted_3 = { class: "bg-white rounded-lg h-full w-full overflow-auto" }
const _hoisted_4 = { class: "flex flex-col justify-center items-center title1 text-SC_Azul text-center p-5 mt-3 gap-4" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "flex flex-row justify-between mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.store.getters.modalVincularPromoterOutroEvento.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h1", null, _toDisplayString($setup.eventoV.nome), 1),
              _createElementVNode("img", {
                class: "img",
                src: `https://santocartao-files.s3.amazonaws.com/event-pictures/${$setup.eventoV.id}.jpg`
              }, null, 8, _hoisted_5),
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "font-normal" }, " Você realmente deseja vincular ", -1)),
              _createTextVNode(" " + _toDisplayString($setup.eventoV.quantidade) + " promoter(s): ", 1),
              _createElementVNode("p", null, [
                _cache[2] || (_cache[2] = _createTextVNode(" ao evento ")),
                _createElementVNode("span", _hoisted_6, _toDisplayString($setup.eventoNome), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "botaoFechar",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)))
            }, "Fechar"),
            _createElementVNode("button", {
              class: "botaoConfirmar",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.confirmar && $setup.confirmar(...args)))
            }, "Confirmar")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}