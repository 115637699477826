import store from '../../store/index'

export default {
    open(callback=() => {}, evento){
        store.commit('modalVincularPromoterOutroEvento',{
            display: true,
            evento:evento,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalVincularPromoterOutroEvento', {
            display: false,
            evento: {},
            callbackOK: () => {}
        });
    },
}