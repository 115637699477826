import store from '../../store/index'

export default {
    open(liberacaoIngresso, callback=() => {}){
        store.commit('modalLiberarIngresso',{
            display: true,
            liberacaoIngresso:liberacaoIngresso,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalLiberarIngresso', {
            display: false,
            liberacaoIngresso: {},
            callbackOK: () => {}
        });
    },
}