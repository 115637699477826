import store from '../../store/index'

export default {
    open(promoter, callback=() => {}){
        store.commit('modalDesvincularPromoter',{
            display: true,
            promoter:promoter,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalDesvincularPromoter', {
            display: false,
            promoter: {},
            callbackOK: () => {}
        });
    },
}