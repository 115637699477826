<template>
  <div v-if="store.getters.modalVincularPromoterOutroEvento.display"
    x-transition:enter="transition ease-out duration-150" x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100" x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <div class="flex flex-col justify-center items-center title1 text-SC_Azul text-center p-5 mt-3 gap-4">

          <h1>{{ eventoV.nome }}</h1>

          <img class="img" :src="`https://santocartao-files.s3.amazonaws.com/event-pictures/${eventoV.id}.jpg`" />


          <p class="font-normal"> Você realmente deseja vincular </p>
          {{ eventoV.quantidade }} promoter(s):
          <p> ao evento
          <span class="font-bold"> {{ eventoNome }} </span>
          </p>
        </div>

      </div>
      <div class="flex flex-row justify-between mt-2">
        <button class="botaoFechar" @click="close">Fechar</button>
        <button class="botaoConfirmar" @click="confirmar">Confirmar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";

export default {
  setup() {
    const store = useStore();
    const eventoV = ref()
    const eventoInfo = inject("evento") as any;
    const eventoNome = ref('')

    const close = () => {
      store.commit("modalVincularPromoterOutroEvento", { display: false, evento: {}, callbackOK: () => { } });
    };


    eventoNome.value = eventoInfo.getEvento().nome

    watch(
      () => [store.getters.modalVincularPromoterOutroEvento.display],
      () => {
        if (store.getters.modalVincularPromoterOutroEvento.display) {
          eventoV.value = store.getters.modalVincularPromoterOutroEvento.evento
        } else {
          eventoV.value = {}
        }
      })

    const confirmar = async () => {
      store.getters.modalVincularPromoterOutroEvento.callbackOK();
    };


    return { store, close, eventoV, eventoNome, confirmar };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #408135;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #408135;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #2a5523;
  color: white;
}

.img {
  border-radius: 5px;
  width: 140px;
  height: 140px;
}
</style>