import store from '../../store/index'

export default {
    open(callback=() => {}, promoter){
        store.commit('modalVincularPromoter',{
            display: true,
            promoter:promoter,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalVincularPromoter', {
            display: false,
            promoter: {},
            callbackOK: () => {}
        });
    },
}