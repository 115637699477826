const List = [
  {
    icon: "home",
    label: "Dashboard",
    name: "Dashboard",
    path: "/dashboard",
    component: () => import("../views/Home.vue"),
  },

  //Relatórios
  {
    icon: "chart-line",
    label: "Relatórios",
    name: "Relatório",
    path: "/relatorios",
    component: () => import("../views/Relatorios/index.vue"),
  },
  {
    icon: "qrcode",
    label: "Leituras",
    name: "Leituras",
    path: "/portaria",
    component: () => import("../views/Relatorios/posEvento.vue"),
  },
  {
    show: false,
    icon: "file-document",
    label: "Relatórios",
    name: "Relatório/Editar",
    path: "/relatorios/editar/:id?",
    component: () => import("../views/Relatorios/update.vue"),
  },
  {
    show: false,
    icon: "print",
    label: "Imprimir Relatório",
    name: "Imprimir Relatório",
    path: "/relatorio/imprimir",
    component: () => import("../views/Relatorios/impressao.vue"),
  },
  {
    show: true,
    icon: "dollar",
    label: "Financeiro",
    name: "Financeiro",
    path: "/financeiro",
    component: () => import("../views/Financeiro/index_v2.vue"),
  },

  //  Promoter
  {
    show: false,
    icon: "users",
    label: "Promoters",
    name: "Promoters",
    path: "/promoters8d82651b52b5edceaa4218c2dc713255",
    component: () => import("../views/Promoter/index.vue"),
  },
  {
    show: false,
    icon: "users",
    label: "Emissão Voucher",
    name: "Emissão Voucher",
    path: "/promoters8d82651b52b5edceaa4218c2dc713255/emitirVoucher",
    component: () => import("../views/Promoter/emitirVoucher.vue"),
  },
  {
    show: false,
    icon: "users",
    label: "Liberar ingresso",
    name: "Liberar ingresso",
    path: "/promoters8d82651b52b5edceaa4218c2dc713255/liberarIngresso",
    component: () => import("../views/Promoter/liberarIngresso.vue"),
  },
  {
    show: false,
    name: "Extrato",
    path: "/promoters8d82651b52b5edceaa4218c2dc713255/:id/extrato",
    component: () => import("../views/Promoter/extrato.vue"),
  },
  {
    show: true,
    icon: "envelope",
    label: "Suporte",
    name: "Suporte",
    path: "/suporte",
    component: () => import("../views/Suporte/index.vue"),
  },
  {
    show: false,
    icon: "print",
    label: "Imprimir Finanças",
    name: "Imprimir Finanças",
    path: "/financeiro/imprimir",
    component: () => import("../views/Financeiro/impressao.vue"),
  },
  {
    show: false,
    icon: 'user',
    name: "Minha Conta",
    path: "/perfil",
    component: () => import("../views/Perfil/index.vue")
  },
];

export const ListMobile = [
  {
    icon: "home",
    label: "Dashboard",
    name: "Dashboard",
    path: "/dashboard",
    component: () => import("../views/Home.vue"),
  },

  //Relatórios
  {
    icon: "chart-line",
    label: "Relatórios",
    name: "Relatório",
    path: "/relatorios",
    component: () => import("../views/Relatorios/index.vue"),
  },
  {
    icon: "qrcode",
    label: "Leituras",
    name: "Leituras",
    path: "/portaria",
    component: () => import("../views/Relatorios/posEvento.vue"),
  },
  {
    show: false,
    icon: "file-document",
    label: "Relatórios",
    name: "Relatório/Editar",
    path: "/relatorios/editar/:id?",
    component: () => import("../views/Relatorios/update.vue"),
  },
  {
    show: false,
    icon: "print",
    label: "Imprimir Relatório",
    name: "Imprimir Relatório",
    path: "/relatorio/imprimir",
    component: () => import("../views/Relatorios/impressao.vue"),
  },
  {
    show: true,
    icon: "dollar",
    label: "Financeiro",
    name: "Financeiro",
    path: "/financeiro",
    component: () => import("../views/Financeiro/index_v2.vue"),
  },

  //  Promoter
  {
    show: false,
    icon: "users",
    label: "Promoters",
    name: "Promoters",
    path: "/promoters8d82651b52b5edceaa4218c2dc713255",
    component: () => import("../views/Promoter/indexTemp.vue"),
  },
  {
    show: false,
    icon: "users",
    label: "Emissão Voucher",
    name: "Emissão Voucher",
    path: "/promoters/emitirVoucher",
    component: () => import("../views/Promoter/emitirVoucher.vue"),
  },
  {
    show: false,
    icon: "users",
    label: "Liberar ingresso",
    name: "Liberar ingresso",
    path: "/promoters8d82651b52b5edceaa4218c2dc713255/liberarIngresso",
    component: () => import("../views/Promoter/liberarIngresso.vue"),
  },
  {
    show: false,
    name: "Extrato",
    path: "/promoters8d82651b52b5edceaa4218c2dc713255/:id/extrato",
    component: () => import("../views/Promoter/extrato.vue"),
  },
  {
    show: true,
    icon: "envelope",
    label: "Suporte",
    name: "Suporte",
    path: "/suporte",
    component: () => import("../views/Suporte/index.vue"),
  },
  {
    show: false,
    icon: "print",
    label: "Imprimir Finanças",
    name: "Imprimir Finanças",
    path: "/financeiro/imprimir",
    component: () => import("../views/Financeiro/impressao.vue"),
  },
  {
    show: true,
    icon: 'user',
    name: "Minha Conta",
    path: "/perfil",
    component: () => import("../views/Perfil/index.vue")
  },
];

export default List;


 
