import store from '../../store/index'


export default {
    open(callback=() => {}){
        store.commit('modalCadastroPainel',{
            display: true,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalCadastroPainel', {
            display: false,
            callbackOK: () => {}
        });
    },
}