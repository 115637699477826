const ListFinanceiro = [
    {
      icon: "home",
      label: "Dashboard",
      name: "Dashboard",
      path: "/dashboard",
      component: () => import("../views/Home.vue"),
    },
    {
      icon: "chart-line",
      label: "Relatórios",
      name: "Relatório",
      path: "/relatorios",
      component: () => import("../views/Relatorios/index.vue"),
    },
    {
      show: false,
      icon: "file-document",
      label: "Relatórios",
      name: "Relatório/Editar",
      path: "/relatorios/editar/:id?",
      component: () => import("../views/Relatorios/update.vue"),
    },
    {
      show: false,
      icon: "print",
      label: "Imprimir Relatório",
      name: "Imprimir Relatório",
      path: "/relatorio/imprimir",
      component: () => import("../views/Relatorios/impressao.vue"),
    },
    {
      show: true,
      icon: "dollar",
      label: "Financeiro",
      name: "Financeiro",
      path: "/financeiro",
      component: () => import("../views/Financeiro/index_v2.vue"),
    },
    {
      show: false,
      icon: "print",
      label: "Imprimir Finanças",
      name: "Imprimir Finanças",
      path: "/financeiro/imprimir",
      component: () => import("../views/Financeiro/impressao.vue"),
    },
    {
      show: false,
      icon: 'user',
      name: "Minha Conta",
      path: "/perfil",
      component: () => import("../views/Perfil/index.vue")
    },
  ];
  
  export const ListMobileFinanceiro = [
    {
      icon: "home",
      label: "Dashboard",
      name: "Dashboard",
      path: "/dashboard",
      component: () => import("../views/Home.vue"),
    },
  
    //Relatórios
    {
      icon: "chart-line",
      label: "Relatórios",
      name: "Relatório",
      path: "/relatorios",
      component: () => import("../views/Relatorios/index.vue"),
    },
    {
      show: false,
      icon: "file-document",
      label: "Relatórios",
      name: "Relatório/Editar",
      path: "/relatorios/editar/:id?",
      component: () => import("../views/Relatorios/update.vue"),
    },
    {
      show: false,
      icon: "print",
      label: "Imprimir Relatório",
      name: "Imprimir Relatório",
      path: "/relatorio/imprimir",
      component: () => import("../views/Relatorios/impressao.vue"),
    },
    {
      show: true,
      icon: "dollar",
      label: "Financeiro",
      name: "Financeiro",
      path: "/financeiro",
      component: () => import("../views/Financeiro/index_v2.vue"),
    },
    {
      show: true,
      icon: "envelope",
      label: "Suporte",
      name: "Suporte",
      path: "/suporte",
      component: () => import("../views/Suporte/index.vue"),
    },
    {
      show: false,
      icon: "print",
      label: "Imprimir Finanças",
      name: "Imprimir Finanças",
      path: "/financeiro/imprimir",
      component: () => import("../views/Financeiro/impressao.vue"),
    },
    {
      show: true,
      icon: 'user',
      name: "Minha Conta",
      path: "/perfil",
      component: () => import("../views/Perfil/index.vue")
    },
  ];
  
  export default ListFinanceiro;
  
  
   
  