import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return ($setup.store.getters.modalConfirmation.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          "x-transition:enter": "transition ease-out duration-150",
          "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
          "x-transition:enter-end": "opacity-100",
          "x-transition:leave": "transition ease-in duration-150",
          "x-transition:leave-start": "opacity-100",
          "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
          onKeydown: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["escape"])),
          class: "w-full px-6 py-4 mb-14 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
          role: "dialog"
        }, [
          _createElementVNode("div", null, [
            _cache[1] || (_cache[1] = _createElementVNode("h6", { class: "text-center" }, "Atenção!", -1)),
            _createElementVNode("div", {
              innerHTML: $setup.store.getters.modalConfirmation.msg
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Button, {
                onClick: $setup.close,
                color: "yellow-500",
                text: "Sair"
              }, null, 8, ["onClick"]),
              _createVNode(_component_Button, {
                onClick: $setup.confirmar,
                color: "primary",
                text: "Confirmar"
              }, null, 8, ["onClick"])
            ])
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}